import './hero.scss';
import video1 from '../../assets/video/video_1.mp4';
import $ from 'jquery';
import AOS from "aos";
import 'aos/dist/aos.css';
import Cloud1 from '../../assets/images/cloud_1.png';
import Cloud2 from '../../assets/images/cloud_2.png';
import Cloud_3 from '../../assets/images/cloud_img.png';

function Hero() {
  AOS.init({
    duration : 1000
  })
  $(document).ready(function() {
    window.addEventListener('scroll', function() {
      const parallaxBg = document.querySelector('.roboimg');
      // const astronaut = document.querySelector('.robocare');
    
      let scrollPosition = window.pageYOffset;
      console.log(scrollPosition);
      parallaxBg.style.transform = `translateY(${scrollPosition * 0.5}px)`;
      
      // Control astronaut floating effect
      // astronaut.style.transform = `translate(-50%, calc(-50% + ${scrollPosition * 0.3}px))`;

      // if (scrollPosition > 200) {
      //   parallaxBg.style.transform = `rotate(${(scrollPosition - 200) * -0.2}deg)`;
      // } else {
      //   parallaxBg.style.transform = `rotate(0deg)`;
      // }
    });
    
  });
  


  
 
  return (
    <div className="herosection">
        <video className='videoTag' autoPlay loop muted>
            <source src={video1} type='video/mp4' />
        </video>
        <div className='container'>
            <div className='titlefnts'>
                <h1 className='nasafnt' data-aos="zoom-in-right"  data-aos-delay="600">We are a different kind of <br/><span>Co-Founders</span> </h1>
                <p data-aos="zoom-in-right"  data-aos-delay="600">We Work for you, and stand by your side every step of the way until you scale up. 🚀</p>
            </div>
        </div>
        <div className='roba_wappers'>
            <div className='robocare'>
                  <div className='roboimg'></div>
            </div>
            <div className='cloud_1 cloud'> <img src={Cloud1} alt="logo"></img></div>
            <div className='cloud_2 cloud'> <img src={Cloud2} alt="logo"></img></div>
            <div className='cloud_3 cloud'> <img src={Cloud1} alt="logo"></img></div> 
            <div className='cloud_4 cloud'><img src={Cloud2} alt="logo"></img></div>
            <div className='cloud_5 cloud'><img src={Cloud2} alt="logo"></img></div>
        </div>
        {/* <div className='cloud_src'>
          <div className='cloud_3 cloud'> <img src={Cloud1} alt="logo"></img></div> 
          <div className='cloud_4 cloud'><img src={Cloud2} alt="logo"></img></div>
          <div className='cloud_5 cloud'><img src={Cloud2} alt="logo"></img></div>
        </div> */}
    </div>
  );
}

export default Hero;
