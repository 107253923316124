import './header_style.scss';
import Logo from '../../assets/images/logo.png';

function Header() {
  return (
    <div className="header_wappers">
        <div className="container">
          <div className='flexrs'>
            <div className='logo'>
                <img src={Logo} alt="logo"></img>
            </div>
            <div className='buttons_enq'>
                <a href="#"><span>North Star Enquiry?</span></a>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Header;
