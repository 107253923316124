import './wapper.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
// import AOS from "aos";
// import 'aos/dist/aos.css';
import Cloud2 from '../../assets/images/cloud_img.png';
import black_img from '../../assets/images/black.png';
import black_img1 from '../../assets/images/black_1.jpg';
import black_img2 from '../../assets/images/black_2.jpg';
import arrow from '../../assets/images/arrow.png';
import plus from '../../assets/images/plus.png';
import Blur from '../../assets/images/blurs.png';
import Roundgraph from '../../assets/images/round_graph.png';
import addcircle from '../../assets/images/add-circle.png';
import yellow from '../../assets/images/yellow.png';
import pinks from '../../assets/images/pinks.png';
import ml_plus from '../../assets/images/ml_plus.png';
import star from '../../assets/images/star.png';
import Nebalwabs from '../../assets/images/nul_waps.png';
import avators from '../../assets/images/avators.png';

import pro_1 from '../../assets/images/pro_1.png';
import pro_2 from '../../assets/images/pro_2.png';
import pro_3 from '../../assets/images/pro_3.png';

import video1 from '../../assets/video/video_2.mp4';
import Blue_Nebula from '../../assets/video/Blue_Nebula.mp4';
import Logo from '../../assets/images/logo.png';
import { FaFacebook } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


function Wapper() {
    // AOS.init({
    //     duration : 1000
    //   })
    $(document).ready(function() {
        // $('.card_click').on('click', function() {
        //     $(this).toggleClass('isflipped');
        // });

        $('.card_click').hover(
            function() {
                $(this).addClass('isflipped');
            },
            function() {
                $(this).removeClass('isflipped');
            }
        );

    });
    
    $(document).ready(function() {
        $('.lftbrand li').hover(function() {
            var index = $(this).index();
            $('.lftbrand li').removeClass('active').find('img').addClass('hide');
            $(this).addClass('active').find('img').removeClass('hide');
            $('.rftbrand li').addClass('hide');
            $('.rftbrand li').eq(index).removeClass('hide');
        }, function() {
        });
    });


    const settings = {
        dots: true,         // Shows navigation dots below the slider
        infinite: true,     // Enables continuous scrolling
        speed: 500,         // Transition speed in milliseconds
        slidesToShow: 3,    // Number of slides to show at a time
        slidesToScroll: 1,   // Number of slides to scroll at a time
        responsive: [
            {
                breakpoint: 1024, // For screens smaller than 1024px
                settings: {
                    slidesToShow: 2, // Show 3 slides at a time
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767, // For screens smaller than 1024px
                settings: {
                    slidesToShow: 1, // Show 3 slides at a time
                    slidesToScroll: 1
                }
            }
        ]
    };

    const settingslinks = {
        dots: true,         // Shows navigation dots below the slider
        infinite: true,     // Enables continuous scrolling
        speed: 500,         // Transition speed in milliseconds
        slidesToShow: 4,    // Number of slides to show at a time
        slidesToScroll: 1,   // Number of slides to scroll at a time
        responsive: [
            {
                breakpoint: 1024, // For screens smaller than 1024px
                settings: {
                    slidesToShow: 2, // Show 3 slides at a time
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767, // For screens smaller than 1024px
                settings: {
                    slidesToShow: 1, // Show 3 slides at a time
                    slidesToScroll: 1
                }
            }
        ]
    };
 

    
    return (
        <>
        {/* <div className="cloud_w">
         <div className='cloud_rs'> <img src={Cloud2} alt="logo"></img></div>
        </div> */}
            <div className="wapper_compaines">
                <div className="container">
                    <div className="tittle">
                        <h2 className='fthel_med' data-aos="fade-right"  data-aos-delay='100'>We Build B2B SaaS Companies: From Prototype
                        to Scale-Up</h2>
                        <p data-aos="fade-right"  data-aos-delay='500'>We partner as co-founders with like-minded entrepreneurs, providing financial resources, infrastructure, and a vast, dedicated team of highly skilled professionals. Our focus, know-how, energy, and entrepreneurial drive guide the journey from inception to scaling up.</p>
                        <p data-aos="fade-right"  data-aos-delay='900'>We exclusively co-found B2B SaaS businesses with a high-velocity business model, targeting the Galaxy. 🌟</p>
                    </div>
                </div>
                <div className='blurwappers'>
                  <img src={Blur}></img>
                </div>
            </div>
            <div className='round_graph'>
                <div className='container'>
                    <div className='graphros' data-aos="zoom-in-up"  data-aos-delay='300'>
                        <img src={Roundgraph}></img>
                    </div>
                </div>
            </div>
           
            <div className='how_weworks'>
                {/* <video className='videoTag' autoPlay loop muted>
                    <source src={video1} type='video/mp4' />
                </video> */}
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-4'>
                            <div className='we_works'>
                                <h2 className="fthel_med" data-aos="fade-up-right"  data-aos-delay="200">How we works?</h2>
                                <p data-aos='fade-up-right'  data-aos-delay='600'>We are your institutional co-founder. We make concrete plans, work proactively with regulators, forge partnerships, and bring our collective expertise to build category-creating companies.</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4'>
                            <div class="scene" data-aos="flip-right"  data-aos-delay="100">
                                <div class="card_click">
                                    <div class="card_face card_facefront">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>We are your different kind of co-founders. </h5>
                                            {/* <p className='otf_reg'>We only accept a few new applications per year.</p> */}
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card_face card_faceback">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>We are your different kind of co-founders. </h5>
                                            <p className='otf_reg'>A business-minded entrepreneur needs a tech-minded co-founder, and vice-versa. We become your co-founders and guide you through the process, inspiring and empowering you the entire time. You’re not alone.</p>
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4'>
                            <div class="scene" data-aos="flip-right"  data-aos-delay="200">
                                <div class="card_click">
                                    <div class="card_face card_facefront face_1">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>Will make you investment-ready.</h5>
                                            {/* <p className='otf_reg'>We only accept a few new applications per year.</p> */}
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card_face card_faceback">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>Will make you investment-ready.</h5>
                                            <p className='otf_reg'>The roadmap to the seed round consists of a lot of hard work, time-consuming tasks, and building a world-class data room for investors is tough. Reaching out to investors is also a daunting job for founders. We hustle like your co-founder and prepare it for investment readiness. </p>
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4'>
                            <div class="scene" data-aos="flip-right"  data-aos-delay="300">
                                <div class="card_click">
                                    <div class="card_face card_facefront face_2">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>Only a few new Ventures per year</h5>
                                            {/* <p className='otf_reg'>We only accept a few new applications per year.</p> */}
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card_face card_faceback">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>Only a few new Ventures per year</h5>
                                            <p className='otf_reg'>Each year, we take on only a select few applications. By doing so, we can maintain a tidy and well-organized workflow across all our initiatives.</p>
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4'>
                            <div class="scene" data-aos="flip-right"  data-aos-delay="400">
                                <div class="card_click">
                                    <div class="card_face card_facefront face_3">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>We are flexible and fractional.</h5>
                                            {/* <p className='otf_reg'>We only accept a few new applications per year.</p> */}
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card_face card_faceback">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>We are flexible and fractional.</h5>
                                            <p className='otf_reg'>We step into these roles when required, reducing your startup's overhead, turnover, recruitment costs, and stress by up to 80%.</p>
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4'>
                            <div class="scene" data-aos="flip-right"  data-aos-delay="500">
                                <div class="card_click">
                                    <div class="card_face card_facefront face_4">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>Entrepreneurship and Intrapreneurship</h5>
                                            {/* <p className='otf_reg'>We only accept a few new applications per year.</p> */}
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card_face card_faceback">
                                        <div className='inndiv'>
                                            <h5 className='otf_reg'>Entrepreneurship and Intrapreneurship</h5>
                                            <p className='otf_reg'>We can develop solutions based on concepts and ideas drawn from our market expertise or from your own ideas and prototypes.</p>
                                            <div className='addcircle'>
                                                <img src={addcircle}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className='explore_more'>
                        <div className='titlw'>
                            <h2 className='fthel_med' data-aos="fade-up-right" data-aos-delay='50'>Explore Our Core Services</h2>
                            <p  data-aos="fade-up-right" data-aos-delay='100'>Discover our comprehensive range of services tailored to 
                            enhance your digital presence.</p>
                        </div>
                        <div className='brand_designs' data-aos-delay='300' data-aos="zoom-in-up">
                            <div className='lftbrand'>
                                <ul>
                                    <li className='fthel_med'>Incorporation <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>Legal & Regulatory <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>Brand Identity   <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>Market Research  <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>UI/UX <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>MVP Development / Prototype  <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>Path to Early Signs of PMF <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>End to End Fundraising  <img src={arrow} className='hide'></img></li>
                                    <li className='fthel_med'>Growth & GTM  <img src={arrow} className='hide'></img></li>
                                </ul>
                            </div>
                            <div className='rftbrand'>
                                <ul>
                                    <li className='hide'>
                                        <img src={black_img} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img1} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img2} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img1} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img2} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img2} alt="logo"></img>
                                    </li>
                                    <li className='hide'>
                                        <img src={black_img} alt="logo"></img>
                                    </li>
                                </ul>
                            </div>
                           
                        </div>
                    </div>

                </div>
            </div>

            <div className='our_focuse'>
                <div className='container'>
                    <h2 className='fthel_med'>Our focus as a <span><img src={plus}></img></span> Creative studio
                    is magnitude of impact. Whether it's millions of visits on your
                    site or millions raised <span><img src={plus}></img></span> in 
                    the next round.</h2>
                </div>
            </div>
            <div className='fedworks'>
                <div className='container'>
                    <div className='listfedworks'>
                        <div className='leftworks'>
                            <h4>Featured Work · 3 projects</h4>
                        </div>
                        <div className='retworks'>
                            <div className='yellow_rs'>
                                <img src={pro_1}></img>
                            </div>
                            <div className='coowappers'>
                                <h5><b>Townystays:</b> A dedicated mobile app platform for hostel and PG living.</h5>
                                <div className='expa'>
                                    <h6>Experience</h6>
                                    <h6>Product</h6>
                                    <h6>Marketing</h6>
                                </div>
                            </div>
                        </div>
                        <div className='retworks flt_lefts'>
                            <div className='yellow_rs'>
                                <img src={pro_2}></img>
                            </div>
                            <div className='coowappers'>
                                <h5><b>Embryo:</b> Empowering Health Every Second Mobile app Platform</h5>
                                <div className='expa'>
                                    <h6>Experience</h6>
                                    <h6>Product</h6>
                                    <h6>Marketing</h6>
                                </div>
                            </div>
                        </div>
                        <div className='pro_3'>
                            <img src={pro_3}></img>
                            <div className='coowappers'>
                                <h5><b>Unaa:</b> Health solutions  for diabetics Pitch Deck designing</h5>
                                <div className='expa'>
                                    <h6>Experience</h6>
                                    <h6>Pitch Deck</h6>
                                    <h6>Marketing</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='starts_marques'>
                <div className="marquee-row">
                    <div className="marquee-block marquee1">
                    <ul className="marquee-item-list">
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                    </ul>  
                    </div>

                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='prowappers'>
                                <h2 className='fthel_med'>From Prototype to Scale-up</h2>
                            </div>
                        </div>    
                        <div className='col-md-6'>
                            <div className='proconts'>
                                <p>We’ll be by your side from the moment you start your prototype until you’re ready to scale into a corporation. Even then, we’ll assist you in securing your next round of funding and attracting fresh talent and innovative ideas.</p>
                                <a href="">Find Out More</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='core_services'>
                    <div className='container'>
                        <div className='roundcircles'>
                            <div className='inlines'>
                                <div className='ourvals'>
                                <h4>Our Values</h4>
                                <img className='' src={ml_plus}></img>
                                </div>     
                                <div className='orsheadings'>
                                    <h2 className='fthel_med'>A Northstar team in a box</h2>
                                    <p>We identify and fill gaps in startups, always being mindful not to step on toes.</p>
                                 </div>

                                <div className='conboxes'>
                                    <div className='boxrs'>
                                        <div className='starts'><img src= {star}></img></div>
                                        <p><b>Multidisciplinary expertise:</b> <br/> Access a wide range of specialised skills without needing to recruit numerous full-time staff.</p>
                                    </div>
                                    <div className='boxrs'>
                                        <div className='starts'><img src= {star}></img></div>
                                        <p><b>Resource Optimisation:</b> <br/> We help manage your resources to fit the changing needs of your growing company.</p>
                                    </div>
                                    <div className='boxrs'>
                                        <div className='starts'><img src= {star}></img></div>
                                        <p><b>Scalability & Access:</b> <br/> Our startup network enhances strategic collaborations, investor access, and market reach.</p>
                                    </div>
                                </div>


                            </div>
                        
                        </div>
                    </div>
                </div>


                <div className="marquee-row">
                    <div className="marquee-block marquee1">
                    <ul className="marquee-item-list">
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                        <li><span className='frs_t'>Click to Start</span><span><img src={pinks}></img></span></li>
                    </ul>  
                    </div>

                </div>

                <div className='what_ourclients'>
                    <div className='container'>
                        <div className='clinenttitle'>
                            <h2 className='fthel_med'>What our Client says</h2>
                        </div>
                        <div className='clisays'>
                            <Slider {...settings}>
                                <div>
                                    <div className='aisliders'>
                                        <p>"Honestly, we had no clue where to start, but Nebula made the whole process feel doable. They didn’t just tell us what to do—they got in the trenches with us, from brainstorming to building out our first version. It felt like we had a whole team rooting for us, and now, seeing our idea actually out there, we’re so grateful."</p>
                                        <div className='murrows'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Gautham</h6>
                                                <span>Founder, Towny Stays</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='aisliders'>
                                        <p>"We had this idea in our heads for ages, but Nebula helped us make it real. They just ‘got it’—the vibe, the brand, the product. And they didn’t just help us launch; they gave us confidence. It’s rare to find people who care this much about your vision!"</p>
                                        <div className='murrows'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Ranjith</h6>
                                                <span>Founder, Embryo</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='aisliders'>
                                        <p>"When you’re starting from scratch, everything feels like a mountain to climb. But with Nebula by our side, we tackled each step together—brand, MVP, fundraising documents , all of it. They took our hand and helped us find our way, and now we’re out there, making waves. Couldn’t have asked for better support!"</p>
                                        <div className='murrows'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Deepan</h6>
                                                <span>Founder, Unaa</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='aisliders'>
                                        <p>"We came to Nebula with just a rough idea, and they helped us shape it into something tangible. They made the whole process feel easy and took away the overwhelm of launching a new business vertical . Nebula didn’t just help us start a business—they helped us believe in our vision. We’re beyond excited to see where this journey takes us with Nebula by our side!
                                        "</p>
                                        <div className='murrows'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Jay</h6>
                                                <span>Founder, Infizion</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           
                            </Slider>
                        </div>
                    </div>
                </div>

                {/* <div className='what_ourclients our_cltrs'>
                    <div className='container'>
                        <div className='clinenttitle'>
                            <h2 className='fthel_med'>Out Teams</h2>
                        </div>
                        <div className='clisays'>
                            <Slider {...settingslinks}>
                                <div>
                                    <div className='aisliders innergroups'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Haritha Murali</h6>
                                                <span>CEO, Media Panda</span>
                                            </div>
                                            <div className='social_links'>
                                                <a href="https://www.linkedin.com/company/buildwithnebula" target='_blank'><FaLinkedin ></FaLinkedin></a>
                                            </div>
                                      
                                    </div>
                                </div>

                                <div>
                                    <div className='aisliders innergroups'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Haritha Murali</h6>
                                                <span>CEO, Media Panda</span>
                                            </div>
                                            <div className='social_links'>
                                                <a href="https://www.linkedin.com/company/buildwithnebula" target='_blank'><FaLinkedin ></FaLinkedin></a>
                                            </div>
                                      
                                    </div>
                                </div>

                                <div>
                                    <div className='aisliders innergroups'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Haritha Murali</h6>
                                                <span>CEO, Media Panda</span>
                                            </div>
                                            <div className='social_links'>
                                                <a href="https://www.linkedin.com/company/buildwithnebula" target='_blank'><FaLinkedin ></FaLinkedin></a>
                                            </div>
                                      
                                    </div>
                                </div>

                                <div>
                                    <div className='aisliders innergroups'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Haritha Murali</h6>
                                                <span>CEO, Media Panda</span>
                                            </div>
                                            <div className='social_links'>
                                                <a href="https://www.linkedin.com/company/buildwithnebula" target='_blank'><FaLinkedin ></FaLinkedin></a>
                                            </div>
                                      
                                    </div>
                                </div>

                                <div>
                                    <div className='aisliders innergroups'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Haritha Murali</h6>
                                                <span>CEO, Media Panda</span>
                                            </div>
                                            <div className='social_links'>
                                                <a href="https://www.linkedin.com/company/buildwithnebula" target='_blank'><FaLinkedin ></FaLinkedin></a>
                                            </div>
                                      
                                    </div>
                                </div>
                                <div>
                                    <div className='aisliders innergroups'>
                                            <div className='avators'>
                                                <img src={avators}></img>
                                            </div>
                                            <div className='murwaps'>
                                                <h6>Haritha Murali</h6>
                                                <span>CEO, Media Panda</span>
                                            </div>
                                            <div className='social_links'>
                                                <a href="https://www.linkedin.com/company/buildwithnebula" target='_blank'><FaLinkedin ></FaLinkedin></a>
                                            </div>
                                      
                                    </div>
                                </div>
                           
                            </Slider>
                        </div>
                    </div>
                </div> */}

                <div className='neables'>
                    <video className='videoTag' autoPlay loop muted width='100%'>
                        <source src={Blue_Nebula} type='video/mp4' />
                    </video>
                </div>

                <div className='get_touchs'>
                    <div className='container'>
                        <div className='gettits'>
                            <h2 className='fthel_med'>Get in touch</h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='address_cons'>
                                    <span>Address</span>
                                    <div className='colgs_techs'>    
                                        <p>2nd floor, Sona College of Technology,Sona Incubation Foundation, Junction Main Rd, Salem - 636 005.</p>
                                        <div className='arrows'>
                                            <img src={pinks}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className='address_cons'>
                                    <span>Email Address</span>
                                    <div className='colgs_techs'>    
                                        <h4 className='nasafnt'>Inception@buildwithnebula.vc </h4>
                                        <div className='arrows'>
                                            <img src={pinks}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className='address_cons'>
                                    <span>Contact Number</span>
                                    <div className='colgs_techs'>    
                                       <h4 className='nasafnt'>+91 96773 84133</h4>
                                        <div className='arrows'>
                                            <img src={pinks}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form_submit'>
                                    <form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                              <div className="mb-3">
                                                <label for="inputs" className="form-label">First Name</label>
                                                <input type="text" className="form-control" id="inputs" placeholder="John"></input>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                              <div className="mb-3">
                                                <label for="inputs" className="form-label">Last Name</label>
                                                <input type="text" className="form-control" placeholder="Doe"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                              <div className="mb-3">
                                                <label for="inputs" className="form-label">Email address</label>
                                                <input type="email" className="form-control" id="inputs" placeholder="name@example.com"></input>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                              <div className="mb-3">
                                                <label for="inputs" className="form-label">Phone Number</label>
                                                <input type="email" className="form-control" id="inputs" placeholder="+91 8888800000"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                              <div className="mb-3">
                                                <textarea className="form-control" id="inputs" placeholder="Describe your requirements"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button className='btn'>Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>

                <div className='get_touchs'>
                    <div className='container'>
                        {/* <div className='gettits'>
                            <h2 className='nasafnt'>FAQ</h2>
                        </div>

                        <div className='faq_contains'>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    Accordion Item #1
                                </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    Accordion Item #2
                                </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Accordion Item #3
                                </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                                </div>
                            </div>
                            </div>
                        </div> */}


                        <div className='letstalks'>
                            <h3 className='nasafnt'>Let’s Talk</h3>
                            <h3 className='nasafnt'><span>inception</span>@buildwithnebula.vc </h3>
                        </div>
                     </div>   
                </div>

                <footer>
                    <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='logos'>
                                <img src={Logo}></img>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='social_links'>
                                <ul>
                                    {/* <li><a href=""><FaFacebook/></a></li>
                                    <li><a href=""><FaInstagramSquare></FaInstagramSquare></a></li> */}
                                    <li><a href="https://www.linkedin.com/company/buildwithnebula" target='_blank'><FaLinkedin ></FaLinkedin></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                </footer>
            </div>
            <div className='footer_row'>
                <p>© 2024 | Nebula.agency. All rights reserved.</p>
            </div>
          
        </>
    )
    }
export default Wapper;