import logo from './logo.svg';
import './App.scss';
import Hero from './components/herosection/Hero';
import Header from './components/header/header';
import Wapper from './components/wapper/wapper';

function App() {
  return (
    <div className="App">
      <Header/>
        <Hero/>
        <Wapper/>
    </div>
  );
}

export default App;
